// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-building-native-mobile-apps-js": () => import("./../src/pages/building-native-mobile-apps.js" /* webpackChunkName: "component---src-pages-building-native-mobile-apps-js" */),
  "component---src-pages-cookies-js": () => import("./../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-outshorts-app-page-js": () => import("./../src/pages/outshorts-app-page.js" /* webpackChunkName: "component---src-pages-outshorts-app-page-js" */),
  "component---src-pages-react-native-component-js": () => import("./../src/pages/react-native-component.js" /* webpackChunkName: "component---src-pages-react-native-component-js" */),
  "component---src-pages-recommendations-js": () => import("./../src/pages/recommendations.js" /* webpackChunkName: "component---src-pages-recommendations-js" */),
  "component---src-pages-redirect-js": () => import("./../src/pages/redirect.js" /* webpackChunkName: "component---src-pages-redirect-js" */),
  "component---src-pages-techstack-js": () => import("./../src/pages/techstack.js" /* webpackChunkName: "component---src-pages-techstack-js" */),
  "component---src-pages-uses-js": () => import("./../src/pages/uses.js" /* webpackChunkName: "component---src-pages-uses-js" */),
  "component---src-templates-main-js": () => import("./../src/templates/main.js" /* webpackChunkName: "component---src-templates-main-js" */)
}

