export default {
  initialColorMode: "light",
  colors: {
    text: "#000",
    background: "#fff",
    modes: {
      dark: {
        text: "#4a4a4a",
        background: "#000",
      },
      yellow: {
        text: "deeppink",
        background: "#000",
      },
    },
  },
}
